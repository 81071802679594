import React from 'react';
import { NavLink } from 'react-router-dom';
import Spinner from '../../images/tail-spin.svg';
import { ReactSVG } from 'react-svg';


export default class selectedTraining extends React.Component {
    state = {
        mounted: false,
        onHumanFactorsQuiz: false,
    }
    componentDidMount() {
       setTimeout(() => {
           this.setState({mounted: true})
       }, 400);
    }

    render() {
        const { training, route, toggleDynamicRoute, type, humanFactorsInfo, index} = this.props;

        const getUrl = () => {
            if (type === "review") {
                return parsedTraining.reviewLink
            } else if (type === "quiz") {
                return parsedTraining.quizLink
            } else if (type === "form") {
                return parsedTraining.link
            } else if (type === "examResults") {
                return parsedTraining.link
            }
        }

        const parsedTraining = JSON.parse(training);

        console.log(parsedTraining.name)
        console.log(parsedTraining.name === "spill prevention" ? true : false)
        const reset = () => {
            toggleDynamicRoute(false, '', '')
        }

        // Only to do with the two Human Factors training & quizzes module in the pages Human Factors(Piolets) & part 135
        const next = () => {   
               
            const createExceptionalObject = () => {
                if(!humanFactorsInfo.reviews)  {
                   return { 
                        name: "human Factors",
                        quizLink: humanFactorsInfo[index].quizLink
                   }
                }
            }

            const checkIfHumanFactors = (count) => humanFactorsInfo.reviews ? humanFactorsInfo.reviews[index + count] : humanFactorsInfo[index + 1];

            if (index !== 2) {
                toggleDynamicRoute(true, route, checkIfHumanFactors(1), "review", humanFactorsInfo, index + 1);
            } else if (index === 2) {
                toggleDynamicRoute(true, route, humanFactorsInfo.reviews ? humanFactorsInfo : createExceptionalObject(), "quiz", humanFactorsInfo, index);
                this.setState((state) => ({onHumanFactorsQuiz: true}))
            } else {
                toggleDynamicRoute(true, route, checkIfHumanFactors(0), "review", humanFactorsInfo, index);
            }
        };

        const prev = () => {

            const checkIfHumanFactors = (count) => humanFactorsInfo.reviews ? humanFactorsInfo.reviews[index - count] : humanFactorsInfo[index - 1];

            if (this.state.onHumanFactorsQuiz) {
                this.setState((state) => ({onHumanFactorsQuiz: false}))
                toggleDynamicRoute(true, route, checkIfHumanFactors(0), "review", humanFactorsInfo, index);
            } else if (index !== 0) {
                toggleDynamicRoute(true, route, checkIfHumanFactors(1), "review", humanFactorsInfo, index - 1);
            } else {
                toggleDynamicRoute(true, route, checkIfHumanFactors(0), "review", humanFactorsInfo, index);
            }
        }

        const navSettings = () => {

        const humanFactorsData = !humanFactorsInfo  ? undefined
                                                    : humanFactorsInfo.reviews
                                                    ? humanFactorsInfo.reviews 
                                                    : humanFactorsInfo; 
                    

        const getNextPart =  !humanFactorsInfo  ? undefined 
                                                : index !== 2 
                                                ? humanFactorsData[index + 1].name.replace("HUMAN FACTORS TRAINING", "")
                                                : this.state.onHumanFactorsQuiz 
                                                ? "" 
                                                : index === 2
                                                ? "Quiz"
                                                : humanFactorsData[index].name.replace("HUMAN FACTORS TRAINING", "")

        const getPrevPart = !humanFactorsInfo   ? undefined 
                                                : this.state.onHumanFactorsQuiz   
                                                ? humanFactorsData[index].name.replace("HUMAN FACTORS TRAINING", "")
                                                : index !== 0 
                                                ? humanFactorsData[index - 1].name.replace("HUMAN FACTORS TRAINING", "")
                                                : index === 0 
                                                ? ""
                                                : humanFactorsData[index].name.replace("HUMAN FACTORS TRAINING", "")

            return (
                <div className="nav-settings">
                    <a className="selected-training-Nav" href="#" onClick={prev}>{getPrevPart}</a>
                    <NavLink className="selected-training-goBack" onClick={reset} to={`${route.location.pathname}`}>{`Go Back to ${route.location.pathname.split("/training/").join("").replace(/-/g," ")}`}</NavLink>
                    <a className="selected-training-Nav" href="#" onClick={next}>{getNextPart}</a>
                </div> )
        }

        const justBackButton = () => (
            <NavLink className="selected-training-goBack" onClick={reset} to={`${route.location.pathname}`}>{`Go Back to ${route.location.pathname.split("/training/").join("").replace(/-/g," ")}`}</NavLink>
        )

        // Only to do with the two Human Factors training & quizzes module in the pages Human Factors(Piolets) & part 135
    

        return (
            <>
                <h2 className="selected-training-heading">{parsedTraining.name}</h2>
                {parsedTraining.name.substring(0,5).trim() === "fuel" ||
                 parsedTraining.name.substring(0,5) === "storm" ||
                 parsedTraining.name === "spill prevention" ? justBackButton()
                                                                : humanFactorsInfo !== undefined
                                                                ? navSettings()
                : justBackButton() }
                <iframe src={getUrl()} 
                        sameorigin="true"    
                        className={type === "quiz" || type === "form" ? "small-iframe" : null}
                        style={type === "quiz" || type === "form" ? {"height" : "385rem"} : null}
                        title={`${type}`}/>
                <ReactSVG src={Spinner} className="spinner" style={this.state.mounted ? {"display" : "none"} : null}/>
            </>
        )
    }
}

