export default [
    {
        page: 'home',
        documents: {
            segregatedPDFs: [
                {
                    title: "inspection programs",
                    documents: [
                        
                        {
                            name: "206L AAIP",
                            link: "https://drive.google.com/file/d/1h_IOF7KBC7F3biTOHaqmBlFjBQfQbZ_C/view?usp=sharing"
                        },
                            {
                            name: "407 AAIP",
                            link: "https://drive.google.com/file/d/1h3ebMAb96rw0G-GTXoPVTedEs5oOVD2I/view?usp=sharing"
                        },
                        {
                            name: "BO-105 AAIP",
                            link: "https://drive.google.com/file/d/13aWNXqfdHXtlUJGiChV7e0krt7cVl1UW/view?usp=sharing"
                        },
                        {
                            name: "412 CAMP",
                            link: "https://drive.google.com/file/d/1iw-3RwSBXRliX8GvxAp3JyjUd3lHrsrH/view?usp=sharing"
                        },
                        
                    ]
                },
                {
                    title: "Power Trend Charts",
                    documents: [
                        {
                            name: "206/407",
                            link: "https://rotor-apps.com/MHI/forms/RR_Eng_Chart.xls"
                        },
                        {
                            name: "412", 
                            link: "https://rotor-apps.com/MHI/forms/Engtc.pdf"  
                        },
                    ]
                },
            ]
        },
        links: [
            {
                name: "Bell Site",
                link: "https://mybell.com/bell/en/USD/login"
            },
            {
                name: "ICA's",
                link: "https://drive.google.com/drive/folders/1oDggiYnR70wQWA-uMSNCGmy1sroagTKV?usp=sharing"
            },
            {
                name: "Rolls-Royce",
                link: "https://www.aviall.com/aviallstorefront/"
            },
            {
                name: "P&W",
                link: "https://customer.pwc.ca/siteminderagent/forms/login.fcc?TYPE=33554433&REALMOID=06-4a837cd9-2df2-46f9-b51f-c3a5d9f6d339&GUID=&SMAUTHREASON=0&METHOD=GET&SMAGENTNAME=xlqp044.pwc.ca&TARGET=-SM-https%3a%2f%2fcustomer%2epwc%2eca%2f"
            },
            {
                name: "Airbus",
                link: "https://airbusworld.helicopters.airbus.com/en/home"
            },
            {
                name: "",
                link: "https://drive.google.com/drive/folders/1IRdGMZr34t-9Ks3Z_99pRvVrH7JiFvmi?usp=sharing"
            },
        ]
    },
    {
        page: 'calm',
        documents: {
            segregatedPDFs: [
                {
                    title: "BELL 206L",
                    documents: [
                       {
                            name: "N305MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/305MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/305MH.pdf",
                        },
                        {
                            name: "N314MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/314MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/314MH.pdf",
                        },
                        {
                            name: "N315MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/315MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/315MH.pdf",
                        },
                        {
                            name: "N316MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/316MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/316MH.pdf",
                        },            
                    ]
                },
                {
                    title: "BELL 407",
                    documents: [
                        {
                            name: "N308MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/308MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/308MH.pdf",
                        },
                        {
                            name: "N309MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/309MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/309MH.pdf",
                        },
                        {
                            name: "N312MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/312MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/312MH.pdf",
                        },
                        {
                            name: "N319MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/319MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/319MH.pdf",
                        },
                        {
                            name: "N325MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/325MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/325MH.pdf",
                        },
                        {
                            name: "N352MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/352MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/352MH.pdf",
                        },
                        {
                            name: "N355MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/355MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/355MH.pdf",
                        },
                        {
                            name: "N356MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/356MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/356MH.pdf",
                        },
                        {
                            name: "N407PA",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/407PA.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/407PA.pdf",
                        },
                        {
                            name: "N407RH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/407RH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/407RH.pdf",
                        },
                        
                    ]
                },
                {
                    title: "BO-105",
                    documents: [
                        {
                            name: "N326MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/326MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/326MH.pdf",
                        },
                        {
                            name: "N327MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/327MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/327MH.pdf",
                        },
                    ]
                },
                {
                    title: "BELL 412",
                    documents: [
                        {
                            name: "N328MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/328MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/328MH.pdf",
                        },
                        {
                            name: "N330MH",
                            abbreviatedlink: "https://rotor-apps.com/MHI/calm/Forecast/330MH.pdf",
                            fullLink: "https://rotor-apps.com/MHI/calm/330MH.pdf",
                        },
                    ]
                },
            ]
        },
        links: [
            {
                name: "Flt Time",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSfX4_lQ8wDAg6P8tItsunQTUCsCKuw7PjNOXKlK7zuSaIPMIQ/viewform"
            },
            {
                name: "Dashboard",
                link: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSr751z0JzvPF91DtykMiN8N7uwHjZ-MOM25I7HJfneLo1tOr-AJdjNXFPu9JOXfOa9D_ZylSmAIlNv/pubhtml?gid=233567636&single=true"
            },
            {
                name: "",
                link: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSr751z0JzvPF91DtykMiN8N7uwHjZ-MOM25I7HJfneLo1tOr-AJdjNXFPu9JOXfOa9D_ZylSmAIlNv/pubhtml?gid=233567636&single=true"
            },
        ]
    },
    {
        page: "manuals",
        documents: {
            easilyAccessedPDFs: [
                {
                    name: "Flight Permit",
                    link: "https://drive.google.com/file/d/1iMpPTEdvGPJh9Vv28jUizrJZ8ehlAevg/view?usp=sharing"
                },
                {
                    name: "Tool Control",
                    link: "https://drive.google.com/file/d/1ixCBSRzCh3j78dsUWZsIT_pNOc0fw_L8/view?usp=sharing"
                },
                {
                    name: "Master Pubs",
                    link: "https://drive.google.com/file/d/1heGGZ_ktsesuaJY6-l9Zcr2ljqS08cSz/view?usp=sharing"
                },
                {
                    name: "Gmm",
                    link: "https://drive.google.com/file/d/1jGSsGDkXMprecCtDJGJ38GlXqViC8xxY/view?usp=sharing"
                },
                {
                    name: "nef",
                    link: "https://drive.google.com/file/d/1hKHVyMs3jdgdhxHwJPxVG6Jn1Q4S52wO/view?usp=sharing",
                },
                {
                    name: "ppmp",
                    link: "https://drive.google.com/file/d/1hhxO-fZ-3uf57sdFTDD7YPAnXyw14vDx/view?usp=sharing",
                },
                {
                    name: "fuel",
                    link: "https://drive.google.com/file/d/1hvPOjN_YVIpdlWQadyIjAMpzrjexNjRq/view?usp=sharing",
                },
                {
                    name: "camp gm",
                    link: "https://drive.google.com/file/d/1iut940cyQ_-yAol9mW78PfMprRbsT8DW/view?usp=sharing",
                },
            ],        
            segregatedPDFs: [
                {
                    title: "MEL",
                    documents: [
                        {
                            name: "206/407",
                            link: "https://drive.google.com/file/d/1i-8r6yI9RzwbfIDOLunsLmnYXryq2JEd/view?usp=sharing",
                        },
                        {
                            name: "bo-105",
                            link: "https://drive.google.com/file/d/1hrHpudOz9fo8nPBIj6TG1Dl_5Ar3Cxd4/view?usp=sharing",
                        },
                        {
                            name: "412",
                            link: "https://drive.google.com/file/d/1i1z4XjyaSs6pE2WicLl28E89yIDT9TtS/view?usp=sharing",
                        },
                        {
                            name: "pa-32",
                            link: "https://drive.google.com/file/d/13ppnKJcnnL2l1oH3pI6Ce2fx7lU3o5ri/view?usp=sharing",
                        },
                                               
                    ]
                },
                {
                    title: "Operations",
                    documents: [
                        {
                            name: "rwsop",
                            link: "https://drive.google.com/file/d/1hHo5KBBJjXSSIlj-XAf-n_7hsjIapALU/view?usp=sharing",
                        },
                        {
                            name: "sefwsop",
                            link: "https://drive.google.com/file/d/1i-tw-yaiE2d_ualc9Mx3Ej0CFyw6czVF/view?usp=sharing",
                        },
                        {
                            name: "fwsop",
                            link: "https://drive.google.com/file/d/1iZtdNtUOJzCD025GdUOnn43-PjlIoRPw/view?usp=sharing",
                        },
                        {
                            name: "ifr sop",
                            link: "https://drive.google.com/file/d/1iTSmdzn7jykh9smqclk7VuvoROwvy5K0/view?usp=sharing",
                        },
                        {
                            name: "nvg sop",
                            link: "https://drive.google.com/file/d/1hcbV7J1JS9rFtIBCmywQWTQtPWV9XLi8/view?usp=sharing",
                        },
                        {
                            name: "gom",
                            link: "https://drive.google.com/file/d/1hWZxusDfQHYwQdD4FVRixnPcOkbH4Z6m/view?usp=sharing",
                        },
                        {
                            name: "rlcfm",
                            link: "https://drive.google.com/file/d/13t9r7O8stKaIjxN06z6IQ2kXk-Quwtwa/view?usp=sharing",
                        },
                        {
                            name: "haz ext load",
                            link: "https://drive.google.com/file/d/1iSchEdIiLGbm_e8gT3IaPH4PTiiDSYOc/view?usp=sharing",
                        },
                        {
                            name: "flight data monitoring",
                            link: "https://drive.google.com/file/d/1h6cAZBXD4plRRkHjBU5VN_X9gsx8l1IN/view?usp=sharing",
                        },
                    ]
                },
                {
                    title: "part 145",
                    documents: [
                        {
                            name: "homer  rs manual",
                            link: "https://drive.google.com/file/d/1iT1cgcN8jy3YpJhap67BVxpaCP4bTWXt/view?usp=sharing",
                        },
                        {
                            name: "homer qc manual",
                            link: "https://drive.google.com/file/d/1hsS5Y5gWkfplLcvKgwirmtARZsOTtmRa/view?usp=sharing",
                        },
                        {
                            name: "fairbanks rs manual",
                            link: "https://drive.google.com/file/d/1jTdTKaX7eL9BBnE31PKfGw2G6J_XoHAK/view?usp=sharing",
                        },
                        {
                            name: "fairbanks qc manual",
                            link: "https://drive.google.com/file/d/1h6h17oPfAbUpFu15Fb90dyKB6RqoU7en/view?usp=sharing",
                        },
                        {
                            name: "rs forms",
                            link: "https://drive.google.com/file/d/1heYnHYr1e5ZGU77x1O6hjLJGZCxJGy2P/view?usp=sharing",
                        },
                        {
                            name: "capabilities list",
                            link: "https://drive.google.com/file/d/15196wjEdfwgPpfoFT3flPt4VewadXgnr/view?usp=sharing",
                        },
                    ]
                },
                {
                    title: "op specs",
                    documents: [
                        {
                            name: "part 133",
                            link: "https://drive.google.com/file/d/1m1vjvytFOdv6mnP4V4ZBNs7UzSQgjqK-/view",
                        },
                        {
                            name: "part 135",
                            link: "https://drive.google.com/file/d/1tmJnn-5-RpV2-gH8lb50j-KvYQ2f_A1X/view?usp=sharing",
                        },
                        {
                            name: "part 137",
                            link: "https://drive.google.com/file/d/1unlJhgoJpxulzYs_bhRcKUraRRJ8st_6/view?usp=sharing",
                        },
                        {
                            name: "part 145h",
                            link: "https://drive.google.com/file/d/1tvrIBQ7RJFROKlCS7kMs91IGoKNQhh9Z/view?usp=share_link",
                        },
                        {
                            name: "part 145f",
                            link: "https://drive.google.com/file/d/1Icv_W-lyMn8S4-CvJPlkOZ1pYo1IWAtz/view?usp=sharing",
                        },
                    ]
                },
                {
                    title: "certifications",
                    documents: [
                        {
                            name: "part 133",
                            link: "https://drive.google.com/file/d/1iQG8ZSz6ET7MzyiDn3LD_XuS6z44APxr/view?usp=sharing",
                        },
                        {
                            name: "part 135",
                            link: "https://drive.google.com/file/d/1icCBHyaiyZTZxa7U5ZZWhaEooBja1dPK/view?usp=sharing",
                        },
                        {
                            name: "part 137",
                            link: "https://drive.google.com/file/d/1hx8nV3k7lbuF4smaJ2qE2DBweQa2ZdFw/view?usp=sharing",
                        },
                        {
                            name: "part 145h",
                            link: "https://drive.google.com/file/d/15eqw86q6zAHeYpgBLKPkwjqRChc0evgj/view?usp=sharing",
                        },
                        {
                            name: "part 145f",
                            link: "https://drive.google.com/file/d/1hDdDVDc6h1blCEsEFeYXz3HtaYrM4aO4/view?usp=sharing",
                        },
                        {
                            name: "part 298",
                            link: "https://drive.google.com/file/d/1i-u0D4nmBSBggasBd1yDw2A9RzlwppQn/view?usp=sharing",
                        },
                    ]
                },
                {
                    title: "rosters",
                    documents: [
                        {
                            name: "inspector stamp",
                            link: "https://drive.google.com/file/d/1uikwF-n0IIObRE0cYr5XllB3bGxqL08r/view?usp=share_link",
                        },
                        {
                            name: "rii",
                            link: "https://drive.google.com/file/d/1tB81Lt8n_CIn310hqEFqeFb6GCZJ-Xqe/view?usp=sharing",
                        },
                        {
                            name: "insp rts",
                            link: "https://drive.google.com/file/d/1hyGHLPh96dlSQPvs0kJUMV8CVWn83FMM/view?usp=drive_link",
                        },
                    ]
                },
            ],
            oasCards: [
                {
                    name: "N305MH",
                    link: "https://drive.google.com/file/d/1x0AOXL3OD5qNIhvlTgNwf5rPIyVTrytU/view?usp=sharing"
                },
                {
                    name: "N308MH",
                    link: "https://drive.google.com/file/d/17-PTjNfevsQMhkb-49JhUdqvCQzksGEH/view?usp=sharing"
                },
                {
                    name: "N309MH",
                    link: "https://drive.google.com/file/d/1v-3Z8CUuU0SgQkfsENUSft-Tl_iPf5hZ/view?usp=sharing"
                },
                {
                    name: "N312MH",
                    link: "https://drive.google.com/file/d/16uzWxLoJUBQsCUg-9EGU3MxwT0pvQR_X/view?usp=drive_link"
                },
                {
                    name: "N314MH",
                    link: "https://drive.google.com/file/d/1v7tv7aPte28vNFVWv0tLKI3QqnUTsNyP/view?usp=sharing"
                },
                {
                    name: "N315MH",
                    link: "https://drive.google.com/file/d/1u7lmxJLCJGsSejZsswDYJLu8ZxcsmWNJ/view?usp=sharing"
                },
                {
                    name: "N316MH",
                    link: "https://drive.google.com/file/d/17e4Jp8FMwgxjuOxB4jMbbDvi0b5ujV2H/view?usp=drive_link"
                },
                {
                    name: "N319MH",
                    link: "https://drive.google.com/file/d/17fti9rIu-EsIiPzFjOUQP_awxvyF14CI/view?usp=sharing"
                },
                {
                    name: "N325MH",
                    link: "https://drive.google.com/file/d/1K9u_cBCblPN2i4ZXZowLMfBfd6nftiua/view?usp=sharing"
                },
                {
                    name: "N326MH",
                    link: "https://drive.google.com/file/d/1v7Y4ntlcgUObQe6jg8kdnMIq4irNT766/view?usp=sharing"
                },
                {
                    name: "N327MH",
                    link: "https://drive.google.com/file/d/1v9pt7eluo6R_RReUo15eydGBTWFJAw0R/view?usp=sharing"
                },
                {
                    name: "N328MH",
                    link: "https://drive.google.com/file/d/16pYuNG-JHR0LUsql384Mv9pydssnxbsG/view?usp=sharing"
                },
                {
                    name: "N330MH",
                    link: "https://drive.google.com/file/d/17hYV5NZwL7hrh-CVylDTrJ-kwYNPssB2/view"
                },
                {
                    name: "N352MH",
                    link: "https://drive.google.com/file/d/14lCT5lLzbeZNr2ExUEjD7clGIL3r0C_A/view?usp=sharing"
                },
                {
                    name: "N355MH",
                    link: "https://drive.google.com/file/d/1hfOpkjxqwDy3bYeZcVgytCGbybTtD1td/view?usp=sharing"
                },
                {
                    name: "N356MH",
                    link: "https://drive.google.com/file/d/1tmIHDxbfpL0rlc51m-lBNxAFSoNht-kl/view?usp=sharing"
                },
                {
                    name: "N407PA",
                    link: "https://drive.google.com/file/d/1pEqx2D1XJ8PXeEyVoipPMw1II-SU4GQd/view?usp=sharing"
                },
                {
                    name: "N407RH",
                    link: "https://drive.google.com/file/d/17pVusZjC3kIchUv8Ihk9UJST1enm2mQ5/view?usp=sharing"
                },
            ],
        },
        links: [
            {
                name: "",
                link: "https://drive.google.com/drive/folders/1XjwEvYqdlAbiAPdkp0wwsIfrIDIP3a7d"
            },
            {
                name: "",
                link: "https://www.aviationhub.aero/Welcome"
            },
            {
                name: "",
                link: "https://airbusworld.helicopters.airbus.com/en/home"
            }

        ]
    },
    {   
        page: "training",
        trainingCategories: [
            {
                type: "part 145",
                quizzes: [
                    {
                        name: "part 145 review",
                        reviewLink: "https://rotor-apps.com/MHI/training/p145rev.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLScLr7cvuPb_liX-PgrIzrOy8fr6BrlTvwkdxqvEtvG7V8V0RQ/viewform"
                    },
                    {
                        name: "repair station manual",
                        reviewLink: "https://rotor-apps.com/MHI/manuals/rsm.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSf7O2h8EtD3_vKzkmguBq0QN5wcq_ifvNFC4yFOvFb6liC3ww/viewform"
                    },
                    {
                        name: "quality control manual",
                        reviewLink: "https://rotor-apps.com/MHI/manuals/qcm.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSeCAtNK849TPcYm-1BWUzewiVh6OzG3SRBYN-F268BHPnm5Uw/viewform"
                    },
                    {
                        name: "incoming recieving inspector*",
                        reviewLink: "https://rotor-apps.com/MHI/training/iritrg.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSek65Wh3DKmxeIZ0OUCV28QOX3tC2EY9jYVPiGfLNAZHeJQew/viewform"
                    },
                    {
                        name: "suspected unapproved parts",
                        reviewLink: "https://rotor-apps.com/MHI/training/sup.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSeTend_5Hu6TcJCAKGhk8pNeyb67HtA-ukpP5nQz4AtJtgsqQ/viewform"
                    },
                    ],
                notes: [
                    {
                        message: "* Only required for Incoming/Recieving Inspectors"
                    }
                ],
                examResults: [
                    {
                        link: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSLRkE-KeXFF8KamOXLC5vI7hD1WS8hhkgimUvQl3uM9h_UHzK3MBUCKF-cHwb7ORtz5b9analJqecG/pubhtml?gid=522318999&single=true",
                        name: "part 145 exam results"
                    }
                ]
            },
            {
                type: "part 135",
                quizzes: [
                    {
                        name: "initial training",
                        reviewLink: "https://rotor-apps.com/MHI/training/Indoc.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSd0aEwnqyZE8f6zWLCJM-_dhY_Qk10dubflplXLX2JZLKeBMQ/viewform"
                    },
                    {
                        name: "part 135 review",
                        reviewLink: "https://rotor-apps.com/MHI/training/Part_135_Req.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLScHdHejIEhEHDVfk17tGAGEmWIo9hD-UjI8tyHpbTOS8as8ug/viewform"
                    },
                    {
                        name: "gmm",
                        reviewLink: "https://rotor-apps.com/MHI/manuals/gmm.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSct0UA8OiBsQYfDaK38cAWBOYsXsmHv9NyI5J0xSaRwRRopew/viewform"
                    },
                    {
                        name: "camp - 412*",
                        reviewLink: "https://rotor-apps.com/MHI/manuals/Camp.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLScFVHaG6EZ4l6JDE4vT9c9X_Me4dpjFuOYQ54lQdDWB-6Q5Dg/viewform"
                    },
                    {
                        name: "rii training**",
                        reviewLink: "https://rotor-apps.com/MHI/manuals/training/RII%20Training.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSe031SJH6YmK9sNd3LjrFjA_IjkukXRIpQ3cJIsERUKffBvWg/viewform"
                    },
                    {
                        name: "rii pilot training",
                        reviewLink: "https://rotor-apps.com/MHI/manuals/training/RIIP.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSeRrQxvVGBcK5tVwbBvsv0UakGNusiu8F33dwX2klwZ9rKGlg/viewform"
                    },
                    {
                        name: "human factors",
                        reviews: [
                            {
                                name: "HUMAN FACTORS TRAINING part 1",
                                reviewLink: "https://rotor-apps.com/MHI/training/HF1.pdf"
                            },
                            {
                                name: "HUMAN FACTORS TRAINING part 2",
                                reviewLink: "https://rotor-apps.com/MHI/training/HF2.pdf" 
                            },
                            {
                                name: "HUMAN FACTORS TRAINING part 3",
                                reviewLink: "https://rotor-apps.com/MHI/training/HF3.pdf"
                            },
                        ],
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSdXfXVzk5YT84EPLw-wN3kPdG7omjC_mhzo_uiVy5vJM1pRxA/viewform"
                    },
                    {
                        name: "hazmat intro",
                        reviewLink: "https://rotor-apps.com/MHI/training/HI.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSeJGJk2FpzWBrpS8hjHsNb6rdiXcGyjI-c9E_mCLPXQks7lnw/viewform"
                    },
                    {
                        name: "hazmat air carrier",
                        reviewLink: "https://rotor-apps.com/MHI/training/HAC.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLScjkeq-zZNDB0ORfTY57fbOi9AfgFiEjHiVVaEavuhYlKosLA/viewform"
                    },
                ],

                notes: [
                    {
                        message: "* Only required for RII Inspectors and Bell 412/DHC-6 Maintenance Personnel",
                    },
                    {
                        message: "** Only required for RII Inspectors",
                    },
                ],
                examResults: [
                    {
                        link: "https://docs.google.com/spreadsheets/d/1JJOK0Ekj2X2H6cSkS4RBxPnwC8xynpob319S29nS7I0/edit?usp=sharing",
                        name: "part 135 exam results"
                    }
                ]
            },
            {
                type: "fuel",
                reviews: [
                    {
                        name: "fuel handling",
                        reviewLink: "https://rotor-apps.com/MHI/training/FHP.pdf"
                    },
                    {
                        name: "fuel recieving",
                        reviewLink: "https://rotor-apps.com/MHI/training/FRP.pdf"
                    },
                    {
                        name: "stormwater training",
                        reviewLink: "https://rotor-apps.com/MHI/training/SWT.pdf"
                    },
                    {
                        name: "spill prevention",
                        reviewLink: "https://rotor-apps.com/MHI/training/SP.pdf"
                    },
                ],
                quizzes: [
                    {
                        name: "fuel handling & recieving",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSeoiyPMPJnC1UIh7s4twTm7vagLzRzUHgj_NI9khKw193hd9A/viewform"
                    },
                    {
                        name: "stormwater and spill prevention",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSeeVgCdwKEdSjiqa50rSuNXjYduV_LXmI8rlN9yc_AGpyp63Q/viewform" 
                    },
                ],

                notes: [
                    {
                        message: "use fuel quality manual for exam",
                        link: "https://rotor-apps.com/MHI/manuals/fqm.pdf"
                    }
                ],

                examResults: [
                    {
                        link: "https://docs.google.com/spreadsheets/d/e/2PACX-1vQIs3Xj1PQpKhuzlzjhvHbRc_VRp6CcTXai5j1bUpy4mHT8d-PtgKRYOH9pCQ_oFSm2cX_Ai0MHttgy/pubhtml?gid=1073782847&single=true",
                        name: "fuel exam results"
                    }
                ]
            },
            {
                type: "human factors",
                reviews: [
                    {
                        name: "HUMAN FACTORS TRAINING part 1",
                        reviewLink: "https://rotor-apps.com/MHI/training/HF1.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSdNOs1qCbTNXSgUjgLsrt-fi0dXLVloT3dU-g_Ovz74gJWO7g/viewform"
                    },
                    {
                        name: "HUMAN FACTORS TRAINING part 2",
                        reviewLink: "https://rotor-apps.com/MHI/training/HF2.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSdNOs1qCbTNXSgUjgLsrt-fi0dXLVloT3dU-g_Ovz74gJWO7g/viewform"
                    },
                    {
                        name: "HUMAN FACTORS TRAINING part 3",
                        reviewLink: "https://rotor-apps.com/MHI/training/HF3.pdf",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSdNOs1qCbTNXSgUjgLsrt-fi0dXLVloT3dU-g_Ovz74gJWO7g/viewform"
                    },
                ],
                quizzes: [
                    {
                        name: "human factors",
                        quizLink: "https://docs.google.com/forms/d/e/1FAIpQLSdNOs1qCbTNXSgUjgLsrt-fi0dXLVloT3dU-g_Ovz74gJWO7g/viewform"
                    }
                ],
                examResults: [
                    {
                        link: "https://docs.google.com/spreadsheets/d/1KPihA0TpLszRh9OJ5ATVT6fFsLY6PO0ug7FiaNs2Cgc/edit?resourcekey#gid=1765503158",
                        name: "human factors exam results"
                    }
                ]
            },
        ]
    },
    {
        page: "safety",
        documents: {
            easilyAccessedPDFs: [
                {
                    name: "safety notes",
                    link: "https://rotor-apps.com/MHI/manuals/Safety_Notes/ssn21.pdf"
                },
                {
                    name: "risk management program",
                    link: "https://drive.google.com/file/d/1iHXEwhtCjROmPqLs0FkNxR3Cq98b8i1q/view?usp=sharing"
                },
                {
                    name: "hse manual",
                    link: "https://drive.google.com/file/d/1h13GgEobR0ExRR2EPlZiEPLi0TY4gX3L/view?usp=sharing"
                },
                {
                    name: "event investigation",
                    link: "https://drive.google.com/file/d/1iKbfWwYBwd6ZWVWIFggKanUdg1WLj_Qi/view?usp=sharing"
                },
                {
                    name: "emergency response plan",
                    link: "https://drive.google.com/file/d/1ip0nv9p7jgzfOTU79mEmBeaIBilJ15zl/view?usp=sharing"
                },
                {
                    name: "internal evaluation program",
                    link: "https://drive.google.com/file/d/13tR845sv60gVnJHK4LWzGyKiBif8-1os/view?usp=sharing"
                },
                {
                    name: "sms manual",
                    link: "https://drive.google.com/file/d/1hO2W3wORq4ZglYyaaqvqkPx_-Ba2I9Yv/view?usp=sharing"
                },
                {
                    name: "Emergency Pocket Checklist",
                    link: "https://drive.google.com/file/d/1sILqoCkOWhrhvE-xCzsCSWkwUJ0-ho3-/view?usp=drive_link"
                },
            ],
            segregatedPDFs: [
                {
                    title: "forms",
                    documents: [
                        {
                            name: "gor",
                            link: "https://rotor-apps.com/MHI/forms/GOR.pdf"
                        },
                        {
                            name: "station checklist", 
                            link: "https://rotor-apps.com/MHI/forms/schecklist.pdf"  
                        },
                        {
                            name: "first report of injury", 
                            link: "https://rotor-apps.com/MHI/forms/fri.pdf"  
                        },
                        {
                            name: "flight irregularity report", 
                            link: "https://rotor-apps.com/MHI/forms/FIR.pdf"  
                        },
                    ]
                },
                {
                    title: "archive",
                    documents: [
                        {
                            name: "2015",
                            link: "https://rotor-apps.com/MHI/Safety_Notes/ssn15.pdf"
                        },
                        {
                            name: "2016", 
                            link: "https://rotor-apps.com/MHI/Safety_Notes/ssn16.pdf"  
                        },
                        {
                            name: "2017", 
                            link: "https://rotor-apps.com/MHI/Safety_Notes/ssn17.pdf"  
                        },
                        {
                            name: "2018", 
                            link: "https://rotor-apps.com/MHI/Safety_Notes/ssn18.pdf"  
                        },
						 {
                            name: "2019", 
                            link: "http://rotor-apps.com/MHI/Safety_Notes/ssn19.pdf"  
                        },
                        {
                            name: "2020", 
                            link: "https://rotor-apps.com/MHI/Safety_Notes/ssn20.pdf"  
                        },
                    ]
                },
            ]
        },
        links: [
            {
                name: "safety data sheet",
                link: "https://sso.verisk.com/"
            },
            {
                name: "iAuditor",
                link: "https://safetyculture.com//"
            },
        ]
    },
    {
        page: "memos",
        documents: {
            segregatedPDFs: [
                {
                    title: "maintenance memos",
                    documents: [
                        {
                            name: "2014",
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2014/m14.pdf"
                        },
                        {
                            name: "2015", 
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2015/m15.pdf"  
                        },
                        {
                            name: "2016", 
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2016/m16.pdf"  
                        },
                        {
                            name: "2017", 
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2017/m17.pdf"  
                        },
                        {
                            name: "2018", 
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2018/m18.pdf"  
                        },
                        {
                            name: "2019", 
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2019/m19.pdf"  
                        },
                        {
                            name: "2020", 
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2020/m20.pdf"  
                        },
                        {
                            name: "2021", 
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2021/m21.pdf"  
                        },
                        {
                            name: "2022", 
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2022/m22.pdf"  
                        },
                        {
                            name: "2023", 
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2023/m23.pdf"  
                        },
                        {
                            name: "2024", 
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2024/m24.pdf"  
                        },
                        {
                            name: "cass", 
                            link: "https://drive.google.com/file/d/1sI8YGL214b3AIbqw0azxFKflY_JrmCrP/view?usp=drive_link"  
                        },
                    ]
                },
                {
                    title: "operation memos",
                    documents: [
                        {
                            name: "2020", 
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2020/o20.pdf"  
                        },
                        {
                            name: "2021", 
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2021/o21.pdf"  
                        },
                        {
                            name: "2022", 
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2022/o22.pdf"  
                        },
                        {
                            name: "2023", 
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2023/o23.pdf"  
                        },
                        {
                            name: "2024", 
                            link: "https://www.rotor-apps.com/MHI/manuals/CAS/2024/o24.pdf"  
                        },
                    ]
                },
            ]
        },
    }
]